<template>
<div>
  <CategoryData @create="createExam"></CategoryData>
</div>
</template>

<script>
import CategoryData from "../myCategory/categoryData"
export default {
  name: "attestation.vue",
  components:{
    CategoryData
  },
  methods:{
    createExam(data){
      console.log('2222')
      let params = {
        op:'create',
        generate_type:0,
        generate_id:data.id,
        // generate_data_rule:this.generateDataRule,//组卷模考
        type:data.type //0练习1考试2课后作业
      };
      if(data.generateDataRule >= 0){
        params.generate_data_rule = data.generateDataRule;
      }
      if(data.generateDataRule == 12){
        this.api.dataset.trainPublishVarious(params).then((res)=>{
          this.$router.push({
            path:'/trainingcamp/random-question',
            query:{
              trainId:res.train_id,
              mapId:data.map_id,
              createType:type,
              generate_id:data.id
            }
          })

        })
      }else{
        this.api.dataset.trainPublish(params).then((res)=>{
          this.$router.push({
            path:'/trainingcamp/materialCreate',
            query:{
              trainId:res.train_id,
              createType:2,   //1 教材 2 认证 3 课程
              categoryId:data.id,
            }
          })
        })
      }

    },
  }
}
</script>

<style scoped>

</style>

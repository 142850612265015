<template>
<div class="cert-manage">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="cert-manage-cont">
    <Attestation></Attestation>
  </div>
</div>
</template>

<script>
import Attestation from './components/myCategory/attestation'
export default {
  name: "certManage.vue",
  components:{
    Attestation
  }
}
</script>

<style scoped lang="scss">
.cert-manage{
  padding: 20px;
  .cert-manage-cont{
    padding: 20px;
    background-color: #FFFFFF;
  }
}
</style>

<template>
  <div class="data-list">
    <div class="search">
      <div style="position: relative">
        <Spin fix v-if="loading"></Spin>
        <div v-else class="search-item">
          <div class="name">
            {{$t('trainingcamp_exam_create_category_first')}}:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeFirstCategory(item)">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="position: relative">
        <Spin fix v-if="secondLoading"></Spin>
        <div class="search-item" v-if="secondCategoryList.length">
          <div class="name">
            {{$t('trainingcamp_exam_create_category_second')}}:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSecondSearch(item)">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>


    </div>

    <!--    <div class="top">-->
    <!--      <div></div>-->
    <!--      <div>-->
    <!--        <Input :placeholder="$t('project_create_data_input')" search clearable style="width: 300px;" v-model="keyword" @on-search="search">-->
    <!--        </Input>-->
    <!--      </div>-->
    <!--    </div>-->
    <div>
      <Table ref="selection" border :columns="columns" :data="dataList" :loading="tableLoading" ></Table>
    </div>
  </div>
</template>

<script>
export default {
  name: "dataList",
  data(){
    return {
      columns: [
        // {
        //   type: 'selection',
        //   width: 60,
        //   align: 'center',
        // },
        {
          title: this.$t('project_create_data_table_id'),
          key: 'id',
        },
        {
          title: this.$t('trainingcamp_exam_create_category_name'),
          key: 'name'
        },
        {
          title: this.$t('trainingcamp_exam_create_category_count'),
          key: 'data_count'
        },
        {
          title: this.$t('project_create_data_table_op'),
          key: 'action',
          width: 180,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.$emit('create', {id:params.row.id,type:1,generateDataRule:2})
                  }
                }
              }, '模考组卷'),
              // h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small'
              //   },
              //   style: {
              //     marginRight: '5px'
              //   },
              //   on: {
              //     click: () => {
              //       this.$emit('create', {id:params.row.id,type:1,generateDataRule:12})
              //     }
              //   }
              // }, '千人千卷'),
            ]);
          }
        }
      ],
      dataList: [
      ],
      loading:false,
      page:1,
      pageSize: 10,
      keyword:'',
      total:0,
      selectDataList:[],
      categoryList:[],  //一级分类
      curCategoryId:'-1', //当前分类
      secondCategoryList:[],  //二级分类
      cursecondCategoryId:'-1', //当前二级分类
      tableLoading:false,
      secondLoading:false,
    }
  },
  props:{
    selectData:{
      type:Array,
      default:()=>{
        return [];
      },
    },
    isEdit:{
      type:Boolean,
      default:true,
    }
  },
  watch:{
    selectData:{
      handler(newV){
        if(newV.length){
          this.selectDataList = newV;
        }
      },
      immediate:true,
    }
  },
  created(){
    this.getList();
  },
  methods:{

    getList(){
      let data = {
        keyword:this.keyword,
        type:0
      };
      this.loading = true;
      this.api.dataset.categoryForm(data).then((res)=>{
        this.loading = false;
        //一级标签
        this.categoryList = [
          ...res.categoryFirstList
        ];
        this.curCategoryId = this.categoryList[0].id;
        this.getSecondList();

      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeFirstCategory(data){
      this.curCategoryId = data.id;
      this.getSecondList();
    },
    getSecondList(){
      let data = {
        keyword:this.keyword,
        category_id:this.curCategoryId,
        type:0
        // second_category_id:this.cursecondCategoryId
      };
      this.secondLoading = true;
      this.api.dataset.categoryForm(data).then((res)=>{
        this.secondLoading = false;
        //一级标签
        this.secondCategoryList = [
          ...res.categorySecondList
        ];
        this.cursecondCategoryId = this.secondCategoryList[0].id;
        this.getThreeList();
      }).catch((err)=>{
        this.secondLoading = false;
      })
    },
    changeSecondSearch(data){
      this.cursecondCategoryId = data.id;
      this.getThreeList();
    },
    getThreeList(){
      let data = {
        keyword:this.keyword,
        category_id:this.curCategoryId,
        category_second_id:this.cursecondCategoryId,
        type:0
      };
      this.tableLoading = true;
      this.api.dataset.categoryForm(data).then((res)=>{
        this.tableLoading = false;
        //三级标签
        this.dataList = [
          ...res.categoryThirdList
        ];
        console.log(this.selectDataList,'this.selectDataList')
        if(this.selectDataList.length){
          this.dataList.forEach((item)=>{
            if(item.id == this.selectDataList[0].id){
              item._checked = true;
            }
            if(!this.isEdit){
              item._disabled = true;
            }
          })
        }

      }).catch((err)=>{
        this.tableLoading = false;
      })
    },
    search(){
      this.getThreeList();
    },
    // create(data){
    //   this.$emit('create', {id:data.id,type:1})
    // },
    // selectDataFn(data){
    //   this.selectDataList = data;
    //   this.$emit('changeData',this.selectDataList)
    // },

  }
}
</script>

<style scoped lang="scss">
.data-list{
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
        font-weight: bold;
      }
      .cont{
        padding: 5px 0;
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            //margin: 0 10px 10px 5px;
            margin-right: 20px;
            margin-bottom: 10px;
            cursor: pointer;
          }
          >div.active{

            font-weight: bold;
            color: #4A79FF;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .top{
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    >div:nth-child(1){
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >p{
        margin-left: 20px;
        >span{
          color:#2d8cf0;
        }
      }
    }
  }
  .page{
    margin: 20px 0;
    text-align: right;
  }
}

</style>
